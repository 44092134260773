<div>
	<div class="text-xl mb-1">News</div>
	<!-- <img src="/branding/bbchallenge_logo.svg" alt="The Busy Beaver Challenge's logo" class="h-20" /> -->
	<div class="flex space-x-8 items-center">
		<div class="-ml-4">
			<img
				src="/branding/bbchallenge_logo.svg"
				alt="The Busy Beaver Challenge's logo"
				class="h-44"
			/>
		</div>
		<ul class="flex flex-col space-y-2 mt-2 list-disc">
			<li>
				<a
					href="https://discuss.bbchallenge.org/t/july-2nd-2024-we-have-proved-bb-5-47-176-870/237"
				>
					<span class="underline">July 2nd 2024</span>
				</a>
			</li>
			<li>
				<a
					href="https://discuss.bbchallenge.org/t/may-2nd-2024-releasing-bouncers-only-2-833-machines-to-go/231"
					><span class="underline">May 2nd 2024</span></a
				>
			</li>
			<li>
				<a
					href="https://discuss.bbchallenge.org/t/april-9th-2023-503-169-new-machines-have-been-decided-only-32-632-left-to-go/"
					><span class="underline">April 9th 2023</span></a
				>
			</li>
			<!-- <li>
				<a
					href="https://discuss.bbchallenge.org/t/december-25th-2022-1-002-823-new-machines-have-been-decided/"
					><span class="underline">December 25th 2022</span></a
				>
			</li> -->
		</ul>
	</div>
</div>
